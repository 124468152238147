<template>
  <div class="new-sender-card">
    <div class="new-sender-card__title">
      <strong>Configuração de sender</strong>
      <br />
      <small>Insira abaixo as informações para criar um novo sender</small>
    </div>

    <v-form ref="form">
      <div class="new-sender-card__body my-4">
        <div class="item">
          <z-input
            v-model="sender.country_code"
            :hideDetails="true"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            label="Country Code"
            type="text"
          />
        </div>

        <div class="item">
          <z-input
            v-model="sender.phone"
            :hideDetails="true"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            label="Phone Number"
            type="text"
          />
        </div>

        <div class="item">
          <z-input
            v-model="sender.display_name"
            :hideDetails="true"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            label="Display Name"
            type="text"
          />
        </div>

        <div class="item">
          <z-autocomplete
            v-model="sender.type"
            :items="types"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            :hideDetails="true"
            label="Type"
          />
        </div>

        <div class="item">
          <z-autocomplete
            v-model="sender.locale"
            :items="langs"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            :hideDetails="true"
            label="Locale"
          />
        </div>
      </div>
    </v-form>

    <div class="new-sender-card--actions d-flex align-center justify-end">
      <z-btn
        class="mr-2"
        text="Cancelar"
        small
        :rounded="false"
        @click="$emit('close')"
      />
      <z-btn
        text="Cadsatrar"
        primary
        small
        color="primary"
        :rounded="false"
        @click="submitInfos"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sender: {
        country_code: "",
        phone: "",
        display_name: "",
        type: "",
        locale: "",
      },
      types: ["EXTERNAL_SMS", "EXTERNAL_VOICE", "INFOBIP_OWNED"],
      langs: [
        "af",
        "sq",
        "ar",
        "az",
        "bn",
        "bg",
        "ca",
        "zh_CN",
        "zh_HK",
        "zh_TW",
        "hr",
        "cs",
        "da",
        "nl",
        "en",
        "en_GB",
        "en_US",
        "et",
        "fil",
        "fi",
        "fr",
        "ka",
        "de",
        "el",
        "gu",
        "ha",
        "he",
        "hi",
        "hu",
        "id",
        "ga",
        "it",
        "ja",
        "kn",
        "kk",
        "rw_RW",
        "ko",
        "ky_KG",
        "lo",
        "lv",
        "lt",
        "mk",
        "ms",
        "ml",
        "mr",
        "nb",
        "fa",
        "pl",
        "pt_BR",
        "pt_PT",
        "pa",
        "ro",
        "ru",
        "sr",
        "sk",
        "sl",
        "es",
        "es_AR",
        "es_ES",
        "es_MX",
        "sw",
        "sv",
        "ta",
        "te",
        "th",
        "tr",
        "uk",
        "ur",
        "uz",
        "vi",
        "zu",
        "unknown",
      ],
    };
  },
  methods: {
    submitInfos() {
      if (this.$refs.form.validate()) {
        // CRIAR LÓGICA PARA ENVIAR SENDER
        this.$emit("close", this.sender);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.new-sender-card {
  background: #fff;
  padding: $z-s-1;
  border-radius: 10px;
  &__title {
    strong {
      font-size: 1.2rem !important;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
  }
}
</style>
